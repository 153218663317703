import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';

// material
import {
  Button,
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
} from '@mui/material';

// components
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';

// components
import Page from '../components/Page';

import { USER_ROLE_ADMIN, USER_ROLE_SUPPLIER } from '../constants';

import { API_MASTERDATALOGS_URL } from '../endpoints';

import getHeaders from '../utils/getHeaders';

Header.propTypes = {
  headLabel: PropTypes.object,
};

function Header({ headLabel }) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

async function getMLogs(authtoken, qry) {
  const data = fetch(`${API_MASTERDATALOGS_URL}?q=${qry}`, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sku', label: 'SKU', alignRight: false },
  { id: 'pps', label: 'PPS', alignRight: false },
  { id: 'supplier', label: 'Supplier', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: true },
];

// ----------------------------------------------------------------------

export default function MasterdataLogs({ user }) {
  const navigate = useNavigate();

  const [mLogs, setMLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const authtoken = user.token ? user.token : null;
  console.log(user);
  if (user.role !== USER_ROLE_ADMIN && user.role !== USER_ROLE_SUPPLIER) {
    navigate(`/dashboard`, { replace: true });
  }

  const refreshMLogs = async () => {
    const data2 = await getMLogs(authtoken, qry);
    setMLogs(data2.items);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        refreshMLogs();
      }
    };
    fetchData();
  }, [authtoken, user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mLogs.length) : 0;

  const mdloglist = mLogs;
  const isNotFound = mdloglist?.length === 0;

  const [qry, setQry] = useState('');

  const searchLogs = () => {
    refreshMLogs();
  };

  const handleQryChange = (event) => {
    setQry(event.target.value);
  };

  console.log(mLogs);

  return (
    <Page title="Tasks">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Tasks
          </Typography>
        </Stack>

        <div>
          <TextField label="Enter text" value={qry} onChange={handleQryChange} />
          <Button variant="contained" onClick={searchLogs} sx={{ marginLeft: '20px' }}>
            Search
          </Button>
        </div>
        <br />

        <Card>
          {mdloglist ? (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <Header headLabel={TABLE_HEAD} />
                  <TableBody>
                    {mdloglist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { created, pps, supplier, sku, status } = row;

                      return (
                        <TableRow hover key={sku} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row" padding="normal">
                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                              {sku}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{pps}</TableCell>
                          <TableCell align="left">{supplier}</TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                              {created}
                            </Typography>
                          </TableCell>
                          <TableCell align="right" sx={{ fontSize: '12px' }}>
                            <Label
                              variant="ghost"
                              color={
                                (status === 'synced' && 'success') ||
                                (status === 'error' && 'error') ||
                                (status === 'deleted' && 'warning') ||
                                'info'
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          No data
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          ) : null}

          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={mLogs ? mLogs.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

MasterdataLogs.propTypes = {
  user: PropTypes.object,
};
