import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import { Card, CardHeader, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import OrderEditModal from './OrderEditModal';
import OrderStatusAccordian from './OrderStatusAccordian';
import OrderAssignAccordian from './OrderAssignAccordian';
import Iconify from '../../../components/Iconify';

import { API_ORDERS_URL, API_ORDER_URL } from '../../../endpoints';
import getHeaders from '../../../utils/getHeaders';
import {
  EXCEPTION_CANCEL,
  EXCEPTION_FAILURE_SHIPMENT,
  EXCEPTION_RESHIP,
  EXCEPTION_FRAUD,
  EXCEPTION_REFUND,
  EXCEPTION_REPLACE,
  EXCEPTION_RESHIP_CHILD,
  EXCEPTION_REPLACE_CHILD,
  EXCEPTION_RETURNED_SHIPMENT,
} from '../../../constants';

import OrdersForm from '../../common/OrdersForm';
// ----------------------------------------------------------------------

async function getOrders(url, authtoken) {
  const data = fetch(url, { method: 'GET', headers: getHeaders(authtoken) })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function OrderLink({ item, handleEditOrder }) {
  const handleClick = () => {
    handleEditOrder(item);
  };

  return (
    <Card onClick={handleClick}>
      <Typography variant="h5">{item.order_id}</Typography>
    </Card>
  );
}

function OrderSummary({ item, handleEditOrder, user, status }) {
  const handleClick = () => {
    handleEditOrder(item);
  };

  return (
    <>
      <Grid container spacing={2} onClick={handleClick} sx={{ cursor: 'pointer' }}>
        <Grid item xs={4}>
          <Typography variant="h5" sx={{ color: '#2065D1' }}>
            {item.order_id}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            sx={{ textTransform: 'uppercase', textAlign: 'center', color: item.isFraud ? 'red' : '' }}
          >
            {item.shop_assigned_to && user.supplier !== 'moet' ? `${item.shop_assigned_to}-` : ''}
            {status} {item.isFraud ? <span>(Fraud)</span> : ''}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ textAlign: 'right' }} variant="body2">
            {item.created}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ width: '100%', p: 0, pb: 1, cursor: 'pointer' }} onClick={handleClick}>
        <Typography variant="caption">
          {item.total && item.total !== '' ? (
            <p>
              <strong>Total</strong>: ${item.total},<strong> Tax</strong>: ${item.tax},<strong> State</strong>:{' '}
              <span>{item.state}</span>
              <span>, created:{item.created}</span>, <strong>{item.id}</strong>
              <span>{item.shipping ? `, ${item.shipping}` : ''}</span>
            </p>
          ) : null}
        </Typography>

        <Typography variant="caption">{item.qrytext}</Typography>
        <br />
      </Box>
    </>
  );
}

export default function OrdersList({ user }) {
  const [url, setUrl] = useState('');
  const [orders, setOrders] = useState(null);
  const [page, setPage] = useState(0);
  const [first, setFirst] = useState(false);
  const [editOrder, setEditOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);

  const refreshOrders = async (pg) => {
    const apiURL = `${API_ORDERS_URL}?${url}&pno=${pg}`;
    const data = await getOrders(apiURL, user.token);
    setOrders(data.orders);
    console.log(apiURL);
    setPage(data.pno);
  };

  const refreshOrder = async (id) => {
    const apiURL = `${API_ORDER_URL}/${id}`;
    const data = await getOrders(apiURL, user.token);
    setEditOrder(data.order);

    const dataActions = await getOrders(`${API_ORDER_URL}/${id}/actions`, user.token);
    console.log(dataActions);
    setTasks(dataActions?.items);
  };

  const refreshActions = async (id) => {};

  const handleEditOrder = (item) => {
    refreshOrder(item.order_id);
    setOpen(true);
  };

  const removeEditOrder = () => {
    setEditOrder(null);
    setOpen(false);
    refreshOrders(page);
  };

  const callbackRefresh = async (url) => {
    const apiURL = `${API_ORDERS_URL}?${url}&pno=0`;
    setUrl(url);
    console.log(apiURL);
    const data = await getOrders(apiURL, user.token);
    setOrders(data.orders);
    console.log(url);
    setPage(data.pno);
  };

  useEffect(() => {
    if (!first) {
      refreshOrders(0);
    }
    setFirst(true);
  });

  const prevPage = async (e) => {
    e.preventDefault();
    setPage(page - 1);
    refreshOrders(page - 1);
    window.scrollTo(0, 0);
  };

  const nextPage = async (e) => {
    e.preventDefault();
    setPage(page + 1);
    refreshOrders(page + 1);
    window.scrollTo(0, 0);
  };

  const refresh = async () => {
    refreshOrders(page);
  };

  console.log(user);

  let paginateDiv = null;
  if (orders && orders.length > 0) {
    let paginateNext = null;

    if (orders.length > 19) {
      paginateNext = (
        <Button className="paginate noselect" onClick={nextPage} onKeyDown={nextPage} variant="text">
          Next
        </Button>
      );
    }

    paginateDiv = (
      <Box variant="caption" sx={{ width: '100%', textAlign: 'center', p: 1, pb: 1 }}>
        {page > 0 ? (
          <Button onClick={prevPage} onKeyDown={prevPage} variant="text">
            Previous
          </Button>
        ) : null}
        <Typography variant="caption" sx={{ p: 1, pb: 1 }}>
          Page {page + 1}
        </Typography>
        {paginateNext}
      </Box>
    );
  }

  return (
    <>
      <OrderEditModal
        authtoken={user.token}
        removeEditOrder={removeEditOrder}
        order={editOrder}
        setEditOrder={setEditOrder}
        open={open}
        refresh={refresh}
        userRole={user.role}
        tasks={tasks}
      />
      <Card>
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <OrdersForm user={user} callback={callbackRefresh} />
        </Box>
        <br />

        {paginateDiv}

        {orders
          ? orders.map((item, key) => {
              const oid = item.order_id ? item.order_id : item.id;
              const exceptions = item.exceptions;

              const isFraud = exceptions & EXCEPTION_FRAUD; // eslint-disable-line no-bitwise

              const isFailure = exceptions & EXCEPTION_FAILURE_SHIPMENT; // eslint-disable-line no-bitwise
              const isReship = exceptions & EXCEPTION_RESHIP; // eslint-disable-line no-bitwise
              const isRefund = exceptions & EXCEPTION_REFUND; // eslint-disable-line no-bitwise
              const isReplace = exceptions & EXCEPTION_REPLACE; // eslint-disable-line no-bitwise
              const isReshipOrder = exceptions & EXCEPTION_RESHIP_CHILD; // eslint-disable-line no-bitwise
              const isReplaceOrder = exceptions & EXCEPTION_REPLACE_CHILD; // eslint-disable-line no-bitwise
              const isReturnedOrder = exceptions & EXCEPTION_RETURNED_SHIPMENT; // eslint-disable-line no-bitwise
              const isCancelledOrder = exceptions & EXCEPTION_CANCEL; // eslint-disable-line no-bitwise

              let shippingEasy = null;
              if (item.reportMeta && item.reportMeta.summary && item.reportMeta.summary.shipping_easy) {
                shippingEasy = (
                  <span sx={{ color: 'green' }}>
                    ShippingEasy : <strong>exists</strong>
                  </span>
                );
              }

              let fulfillmentStatus = null;
              let isFound = false;
              if (item.metadata && item.metadata.pps) {
                if (item.metadata.pps.fulfillments && item.metadata.pps.fulfillments.length)
                  if (item.metadata.pps.fulfillments[0].tracking_numbers.length > 0) {
                    fulfillmentStatus = (
                      <Typography variant="caption">
                        <span className="tracking">Tracking No.</span>:
                        {item.metadata.pps.fulfillments[0].tracking_numbers[0]}
                        &nbsp;
                        {item.metadata.pps.shipment_status ? <strong>shipment-status:pps</strong> : null}{' '}
                        {item.metadata.pps.shipment_status}{' '}
                        {item.metadata.source.shipment_status ? <strong>shipment-status:store</strong> : null}{' '}
                        {item.metadata.source.shipment_status}
                        &nbsp;
                        <strong>
                          {item.metadata.pps ? `pps:${item.metadata.pps.fulfillment_status}` : ''}
                          &nbsp;
                          {item.metadata.source ? `source:${item.metadata.source.fulfillment_status}` : ''}
                        </strong>
                      </Typography>
                    );
                    isFound = true;
                  }
              }

              if (!isFound && item.metadata && item.metadata.source) {
                if (item.metadata.source.fulfillments && item.metadata.source.fulfillments.length)
                  if (item.metadata.source.fulfillments[0].tracking_numbers.length > 0) {
                    fulfillmentStatus = (
                      <Typography variant="caption">
                        <span className="tracking">Tracking No.</span>:
                        {item.metadata.source.fulfillments[0].tracking_numbers[0]}
                        &nbsp;
                        {item.metadata.source.shipment_status ? <strong>shipment-status:store</strong> : null}{' '}
                        {item.metadata.source.shipment_status}
                        &nbsp;
                        <strong>
                          {item.metadata.source ? `source:${item.metadata.source.fulfillment_status}` : ''}
                        </strong>
                      </Typography>
                    );
                  }
              }

              let status = item.status;

              if (status === 'onhold') {
                status = 'on hold';
              }
              if (status === 'closed') {
                status = 'closed';
              }

              return (
                <Box sx={{ width: '100%', p: 2, pb: 1 }}>
                  <Paper elevation={4} sx={{ p: 2 }}>
                    <OrderSummary item={item} status={status} user={user} handleEditOrder={handleEditOrder} />
                    <Box>
                      <Typography variant="caption">
                        <Link
                          href={item.reportMeta?.summary?.order_status_url}
                          target="_blank"
                          rel="noreferrer"
                          underline="none"
                        >
                          External Link&nbsp;
                          <Iconify
                            sx={{ paddingTop: '3px' }}
                            icon={'eva:external-link-outline'}
                            width={12}
                            height={12}
                          />
                        </Link>
                        &nbsp;
                        {fulfillmentStatus}
                        &nbsp;
                        {user.role === 'admin' && shippingEasy}
                      </Typography>
                      {item.onhold_reason && item.onhold_reason !== '' && item.status === 'onhold' ? (
                        <Alert severity="warning">{item.onhold_reason}</Alert>
                      ) : null}
                      <br />

                      {isFraud || isFailure ? (
                        <Alert severity="error">
                          <Typography variant="caption" sx={{ margin: '14px 0px', fontSize: '12px' }}>
                            <strong>
                              {isCancelledOrder ? 'CANCELLED ' : ''}
                              {isFailure ? 'DELIVERY EXCEPTION ' : ''}
                              {isFraud ? 'FRAUD ' : ''}
                              {isReship ? 'RESHIPPED ' : ''}
                              {isReplace ? 'REPLACED ' : ''}
                              {isRefund ? 'REFUNDED ' : ''}
                              {isReshipOrder ? 'RESHIPMENT ' : ''}
                              {isReplaceOrder ? 'REPLACEMENT ' : ''}
                              {isReturnedOrder ? 'RETURNED SHIPMENT ' : ''}
                            </strong>
                            <br />
                            {item?.tags?.substring(1)}
                          </Typography>
                        </Alert>
                      ) : null}

                      {(isReturnedOrder || isCancelledOrder) && !isFailure && !isFraud ? (
                        <Alert severity="warning">
                          <Typography variant="caption" sx={{ margin: '14px 0px', fontSize: '12px' }}>
                            <strong>
                              {isCancelledOrder ? 'CANCELLED ' : ''}
                              {isReship ? 'RESHIPPED ' : ''}
                              {isReplace ? 'REPLACED ' : ''}
                              {isRefund ? 'REFUNDED ' : ''}
                              {isReshipOrder ? 'RESHIPMENT ' : ''}
                              {isReplaceOrder ? 'REPLACEMENT ' : ''}
                              {isReturnedOrder ? 'RETURNED SHIPMENT ' : ''}
                            </strong>
                            <br />
                            {item?.tags?.substring(1)}
                          </Typography>
                        </Alert>
                      ) : null}

                      {(isReplace || isRefund || isReshipOrder || isReplaceOrder || isReship) &&
                      !isReturnedOrder &&
                      !isFailure ? (
                        <Alert severity="info">
                          <Typography variant="caption" sx={{ margin: '14px 0px', fontSize: '12px' }}>
                            <strong>
                              {isReship ? 'RESHIPPED ' : ''}
                              {isReplace ? 'REPLACED ' : ''}
                              {isRefund ? 'REFUNDED ' : ''}
                              {isReshipOrder ? 'RESHIPMENT ' : ''}
                              {isReplaceOrder ? 'REPLACEMENT ' : ''}
                            </strong>
                            <br />
                            {item?.tags?.substring(1)}
                          </Typography>
                        </Alert>
                      ) : null}
                    </Box>
                  </Paper>
                </Box>
              );
            })
          : ''}
        {paginateDiv}
        <br />
      </Card>
    </>
  );
}

OrdersList.propTypes = {
  user: PropTypes.object,
};
