import * as Yup from 'yup';
import { useState } from 'react';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// State management
import { FORGOT_PWD_URL } from '../../../endpoints';

// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Label from '../../../components/Label';

async function forgotPasswordAPI(payload) {
  return fetch(FORGOT_PWD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = methods;

  const watchEmail = watch('email', '');

  const onSubmit = async () => {
    const data = await forgotPasswordAPI({ email: watchEmail });
    if ('error' in data) {
      setIsError(true);
      setMessage(data?.error);
    } else {
      setMessage(`An email with instructions will be sent to ${watchEmail} if its registered!`);
      setIsError(false);
      reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />
      </Stack>
      <br />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Forgot Password
      </LoadingButton>

      <br />
      <br />
      {message !== '' ? (
        <Label variant="vanilla" color={isError ? 'error' : 'success'}>
          {message}
        </Label>
      ) : null}
    </FormProvider>
  );
}
