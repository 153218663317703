import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import { Table, Stack, Button, TableRow, TableBody, TableCell, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Iconify from '../components/Iconify';

import { OrdersList } from '../sections/@dashboard/orders';

import { API_ORDER_URL } from '../endpoints';

import getHeaders from '../utils/getHeaders';

async function getOrder(authtoken, id) {
  const data = fetch(`${API_ORDER_URL}/${id}`, { method: 'GET', headers: getHeaders(authtoken) })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

export default function Orders({ user }) {
  const authtoken = user.authtoken ? user.authtoken : null;
  const { id, pno } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (id && 2 === 3) {
      const fetchData = async () => {
        const data = await getOrder(authtoken, id);
        setOrder(data.order);
      };
      fetchData();
    }
  }, [authtoken, id]);

  return (
    <Page title="Orders">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Orders {pno}
          </Typography>
        </Stack>
        <OrdersList user={user} />
      </Container>
    </Page>
  );
}

Orders.propTypes = {
  user: PropTypes.object,
};
