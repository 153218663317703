import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Card, Link, Typography, Stack } from '@mui/material';
// utils
import { fCurrency } from '../../../utils/formatNumber';

PPSProductCard.propTypes = {
  product: PropTypes.object,
  handleEditProduct: PropTypes.func,
};

export default function PPSProductCard({ product, handleEditProduct }) {
  const { vtitle: name, price, sku, quantity } = product;

  const handleClick = () => {
    handleEditProduct(product);
  };

  return (
    <Card onClick={handleClick}>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {sku}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>
        <Typography variant="subtitle2" noWrap>
          Inventory: {quantity}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">{price !== '' ? fCurrency(price) : '-'}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
