import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { sentenceCase } from 'change-case';

import Paper from '@mui/material/Paper';

import { useForm } from 'react-hook-form';

import getHeaders from '../../../utils/getHeaders';
import Label from '../../../components/Label';

import { API_TASKS_URL } from '../../../endpoints';

async function addTask(authtoken, id, payload) {
  const data = fetch(`${API_TASKS_URL}/${id}`, {
    method: 'POST',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

const style = {
  position: 'absolute',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 780,
  minHeight: 320,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};

export default function TaskEditModal({ userRole, authtoken, task, removeEditTask, open, setEditTask }) {
  const handleClose = () => {
    removeEditTask();
  };

  const [loading, setLoading] = useState(false);
  const { register, watch, reset, resetField } = useForm();

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState('');

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const watchNote = watch('note', '');
  const watchLinked = watch('linked', '');
  const disabled = watchNote === undefined || watchNote === '' || status === '' || loading;

  const onSubmit = async () => {
    setLoading(true);

    const data = await addTask(authtoken, task.id, { status, note: watchNote, linked_order_id: watchLinked });

    console.log(data);
    if ('error' in data) {
      setIsError(true);
      setMessage(data?.error);
    } else {
      setMessage(data?.status);
      setIsError(false);
      setStatus('');
      resetField('note');
      reset();
    }

    setLoading(false);
  };

  console.log(task);
  console.log(message);
  return (
    <>
      {task ? (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {task.entityID} -{' '}
              <Label variant="ghost" color={(task.status === 'banned' && 'error') || 'success'}>
                {sentenceCase(task.status)}
              </Label>
              <br />
              <br />
              Note: {task.note}
              <br />
              <br />
              <FormControl sx={{ width: 160, marginLeft: 0.75, marginTop: '8px' }}>
                <InputLabel id="demo-simple-select-label">Action</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Action"
                  onChange={handleChange}
                >
                  <MenuItem value="closed">Close</MenuItem>
                  <MenuItem value="complete">Complete</MenuItem>
                </Select>
              </FormControl>
              {task.task === 'reship' || task.task === 'replace' ? (
                <FormControl sx={{ width: 190, marginLeft: 0.75, marginTop: '8px' }}>
                  <TextField {...register('linked')} label="Linked Order ID" inputProps={{ style: { fontSize: 12 } }} />
                </FormControl>
              ) : null}
              <br />
              <br />
              <TextField
                id="outlined-multiline-static"
                {...register('note')}
                label="Message"
                multiline
                rows={3}
                inputProps={{ style: { fontSize: 12, width: '80%', minWidth: '360px' } }}
              />
              <br />
              <Button
                variant="outlined"
                sx={{ marginLeft: '0px', marginTop: '20px', padding: '12px 50px' }}
                onClick={onSubmit}
                disabled={disabled}
              >
                Submit
              </Button>
              {message !== '' ? (
                <Label
                  sx={{ marginTop: '0px', padding: '12px 10px' }}
                  variant="vanilla"
                  color={isError ? 'error' : 'success'}
                >
                  {message}
                </Label>
              ) : null}
            </Box>
          </Modal>
        </>
      ) : null}
    </>
  );
}

TaskEditModal.propTypes = {
  removeEditTask: PropTypes.func,
  authtoken: PropTypes.string,
  open: PropTypes.bool,
  setEditTask: PropTypes.func,
  userRole: PropTypes.string,
  task: PropTypes.object,
};
