import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';

import { Orders, OrderItems } from '../sections/@dashboard/reports';

export default function Reports({ user }) {
  const { id } = useParams();

  return (
    <Page title="Reports">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Reports
          </Typography>
        </Stack>

        {id ? null : <Orders user={user} />}
        {id === 'order-summary' && <Orders user={user} />}
        {id === 'order-details' && <OrderItems user={user} />}
      </Container>
    </Page>
  );
}

Reports.propTypes = {
  user: PropTypes.object,
};
