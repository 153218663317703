export const USER_TOKEN = `EZF_USER_TOKEN`;

export const MISSING_IMAGE_URL = `https://storage.googleapis.com/ezflo/images/ezf_no-image.jpeg`;

// USER ROLES
export const USER_ROLE_ADMIN = `admin`;
export const USER_ROLE_PPSADMIN = `pps-admin`;
export const USER_ROLE_SUPPLIER = `supplier`;

// Order Filter
export const ORDER_FORM_FILTER_STATUS = ['Assigned', 'Unassigned', 'OnHold', 'Suspicious', 'Closed', 'Fulfilled'];

// Order Filter
export const ORDER_FORM_FILTER_STATUS_ADMIN = [
  'Assigned',
  'Assigning',
  'Unassigned',
  'OnHold',
  'Redundant',
  'Suspicious',
  'Closed',
  'Fulfilled',
];

// Order Filter Exceptions
export const ORDER_FORM_EXCEPTIONS = [
  'Cancel',
  'Delivery Exception',
  'Missing Shipping Label',
  'Fraud',
  'Refund',
  'Replace',
  'Reship',
  'Return Received',
];

// Order Exceptions
export const EXCEPTION_FRAUD = 1;
export const EXCEPTION_RESHIP = 2;
export const EXCEPTION_REFUND = 4;
export const EXCEPTION_REPLACE = 8;
export const EXCEPTION_FAILURE_SHIPMENT = 16; // USED ELSEWHERE
export const EXCEPTION_RETURNED_SHIPMENT = 32;
export const EXCEPTION_RESHIP_CHILD = 64;
export const EXCEPTION_REPLACE_CHILD = 128;
export const EXCEPTION_CANCEL = 256;
export const EXCEPTION_SHIPPINGEASYMISS = 512;
