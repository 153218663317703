import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

// hooks
import useResponsive from '../hooks/useResponsive';

// components
import Page from '../components/Page';
import Logo from '../components/Logo';

import { API_PPS_ORDER } from '../endpoints';

// sections

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

async function getPPSOrder(id) {
  const data = fetch(`${API_PPS_ORDER}?token=${id}`, {
    method: 'GET',
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

function LineItemProps({ properties }) {
  return (
    <Typography variant="caption">
      {properties.map(({ name, value }) => (
        <div>
          {name} : {value}
        </div>
      ))}
    </Typography>
  );
}

function OrderModalHeader({ order }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="h6" sx={{ textTransform: 'uppercase', textAlign: 'left' }}>
          <Link href={order?.data?.order_status_url} target="_blank" rel="noreferrer" underline="none">
            {order.order_id}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="h6"
          sx={{ textTransform: 'uppercase', textAlign: 'center', color: order.isFraud ? 'red' : '' }}
        >
          {order.status} {order.isFraud ? <span>(Fraud)</span> : ''}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ textAlign: 'right' }} variant="body2">
          {order.created}
        </Typography>
      </Grid>
    </Grid>
  );
}

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function OC() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const { register, watch, reset } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPPSOrder(id);
      setOrder(data.order);
    };
    fetchData();
  }, [id]);

  const watchNote = watch('note', '');
  const disabled = watchNote === undefined || watchNote === '' ;

  const onSubmit = async () => {
    alert('call api');
  };

  console.log(order);

  let oDetails = null;

  if (order) {
    oDetails = (
      <Box>
        <OrderModalHeader order={order} />
        {order.data.note && order.data.note !== '' ? (
          <Alert severity="info">Notes (Gifts) : {order.data.note}</Alert>
        ) : null}
        <br />
        Total : ${order.reportMeta?.summary?.total_price} ( Shipping : ${order.reportMeta?.summary?.shipping_total} )
        <br />
        <strong>Items</strong>
        
          {order.data.line_items
            ? order.data.line_items.map((item, key) => {
                const { properties, title, sku, price, quantity } = item;
                console.log(properties);
                return (
                  <div>
                    - {item.title} {item.sku} (${item.price}x{item.quantity})
                    {properties && properties.length ? <LineItemProps properties={properties} /> : null}
                  </div>
                );
              })
            : ''}
        <div sx={{fontSize:'9px'}}>
        <strong>Shipping To : </strong>
        {order.data.shipping_address?.first_name ? order.data.shipping_address?.first_name : ''}
              &nbsp;{order.data.shipping_address?.last_name ? order.data.shipping_address?.last_name : ''}
              <br />
              {order.data.shipping_address?.address1}, {order.data.shipping_address?.city ? `${order.data.shipping_address?.city}, ` : ''}
              {order.data.shipping_address?.province_code ? `${order.data.shipping_address?.province_code}, ` : ''}
              {order.data.shipping_address?.zip ? order.data.shipping_address.zip : ''}
        </div> 
      </Box>
    );
  }

  return (
    <Page title="OC">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            {oDetails}

            <Box mt={2}>
              <Typography variant="h4" color="error" gutterBottom>
                Decline Order
              </Typography>
            </Box>
            <Box mt={1}>
            
              <TextField
                id="outlined-multiline-static"
                {...register('note')}
                multiline
                rows={4}
                variant="outlined"
                label="Reason"
                fullWidth
              />
          </Box>
          <Box mt={2}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={onSubmit}
              disabled={disabled}>
              Submit
            </Button>
          </Box>
  
          
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

OC.propTypes = {};
