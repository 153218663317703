import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { useForm } from 'react-hook-form';
import { API_SUPPLIER_URL, API_MASTERDATA_STORE_PUSH } from '../../../endpoints';
import getHeaders from '../../../utils/getHeaders';
import Label from '../../../components/Label';

const style = {
  position: 'absolute',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 640,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};

const styleInputsSmall = {
  width: '120px',
  marginRight: '10px',
  marginBottom: '10px',
};

async function updateSupplierProduct(authtoken, id, payload) {
  const data = fetch(`${API_SUPPLIER_URL}/${id}/masterdata/update`, {
    method: 'POST',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

async function pushToShopfy(authtoken, supplier, pid) {
  const data = fetch(`${API_MASTERDATA_STORE_PUSH}?supplier=${supplier}&pid=${pid}`, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

export default function SupplierProductEditModal({ refresh, authtoken, supplier, product, removeEditProduct, open }) {
  const { register, watch, reset } = useForm();

  const handleClose = () => {
    reset();
    removeEditProduct();
    setMessage();
  };

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(product?.status);
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const onShopifyPushSubmit = async () => {
    setLoading(true);
    setMessage('');

    const data = await pushToShopfy(authtoken, supplier.name, product.id);
    if ('error' in data) {
      setIsError(true);
      const truncatedM = data?.error.slice(0, 64) + (data?.error.length > 64 ? '......' : '');
      setMessage(truncatedM);
    } else {
      let m = data?.message;

      if ('res' in data) {
        m = `${data.res.action}!`;
        if ('product' in data.res) {
          m = `${data.res.action}! Shopify Product ID - ${data.res.product?.product?.id}`;
        }
      }

      setMessage(m);
      setIsError(false);
    }

    setLoading(false);
  };

  console.log(product);
  return (
    <div>
      {product ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <h3>View - {product?.title}</h3>
              <b>SKU: {product?.sku}</b>
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Button onClick={onShopifyPushSubmit} disabled={loading} variant="outlined">
                Push to Store
              </Button>
              <br />
              {message !== '' ? (
                <Label
                  variant="vanilla"
                  color={isError ? 'error' : 'success'}
                  sx={{ maxWidth: '640px', wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {message}
                </Label>
              ) : null}

              <div sx={{ display: 'none;' }}>
                <Button variant="outlined" color="secondary" onClick={handleClose} sx={{ display: 'none;' }}>
                  Close
                </Button>
                <br />
                <br />
              </div>
              <Box sx={{ height: '240px', overflowY: 'scroll', padding: '10px', background: '#fbfbfb' }}>
                <div dangerouslySetInnerHTML={{ __html: product?.body_html?.replace(/\n/g, '<br/>') }} />
              </Box>

              <br />
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}

SupplierProductEditModal.propTypes = {
  removeEditProduct: PropTypes.func,
  product: PropTypes.object,
  open: PropTypes.bool,
  supplier: PropTypes.object,
  refresh: PropTypes.func,
};
