import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useForm } from 'react-hook-form';
import getHeaders from '../../../utils/getHeaders';
import Label from '../../../components/Label';
import { API_ORDER_URL } from '../../../endpoints';

import { EXCEPTION_RETURNED_SHIPMENT } from '../../../constants';

async function addTask(authtoken, id, payload) {
  const data = fetch(`${API_ORDER_URL}/${id}/actions`, {
    method: 'POST',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

async function getTasks(authtoken, id) {
  console.log(`${API_ORDER_URL}/${id}/actions`);
  const data = fetch(`${API_ORDER_URL}/${id}/actions`, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

export default function OrderActionsAccordian({ authtoken, order, tasks, userRole }) {
  const [loading, setLoading] = useState(false);
  const { register, watch, reset } = useForm();

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [type, setType] = useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const watchNote = watch('note', '');
  const disabled = watchNote === undefined || watchNote === '' || type === '' || loading;

  const onSubmit = async () => {
    setLoading(true);

    const data = await addTask(authtoken, order.order_id, { type, note: watchNote });

    if ('error' in data) {
      setIsError(true);
      setMessage(data?.error);
    } else {
      setMessage(data?.status);
      setIsError(false);
      setType('');
      reset();
    }

    setLoading(false);
  };

  console.log(authtoken);
  const isReturnReceived = order?.exceptions & EXCEPTION_RETURNED_SHIPMENT; // eslint-disable-line no-bitwise
  console.log(isReturnReceived);

  return (
    <>
      <Accordion>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="h6">Request/Action/Notes</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ overflowY: 'scroll', maxHeight: '420px' }}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                width: '100%',
                backgroundColor: '#fbfbfb',
                padding: '10px',
              },
              '& .MuiTextField-root': { m: 1, width: '97%' },
            }}
          >
            <Paper variant="outlined">
              <TextField
                id="outlined-multiline-static"
                {...register('note')}
                label="Message"
                multiline
                rows={3}
                inputProps={{ style: { fontSize: 12 } }}
              />

              <FormControl sx={{ width: 160, marginLeft: 0.75, marginTop: '8px' }}>
                <InputLabel id="demo-simple-select-label">Action</InputLabel>

                {order.status !== 'fulfilled' ? (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Action"
                    onChange={handleChange}
                  >
                    <MenuItem value="note">Note</MenuItem>
                    <MenuItem value="cancel">Cancel</MenuItem>
                    <MenuItem value="edit">Edit</MenuItem>
                  </Select>
                ) : null}
                {order.status === 'fulfilled' ? (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Action"
                    onChange={handleChange}
                  >
                    <MenuItem value="note">Note</MenuItem>
                    <MenuItem value="refund">Refund</MenuItem>
                    {isReturnReceived ? <MenuItem value="reship">ReShip</MenuItem> : null}
                    <MenuItem value="replace">Replace</MenuItem>
                    {userRole === 'admin' ? <MenuItem value="return-received">Return Received</MenuItem> : null}
                  </Select>
                ) : null}
              </FormControl>
              <Button
                variant="outlined"
                sx={{ marginLeft: '30px', marginTop: '10px', padding: '12px 50px' }}
                onClick={onSubmit}
                disabled={disabled}
              >
                Submit
              </Button>
              {message !== '' ? (
                <Label
                  sx={{ marginLeft: '10px', marginTop: '14px', padding: '12px 10px' }}
                  variant="vanilla"
                  color={isError ? 'error' : 'success'}
                >
                  {message}
                </Label>
              ) : null}
            </Paper>

            <br />
            <br />
            {tasks && tasks.length !== 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, marginBottom: '50px' }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Details</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Created/Updated</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks.map((task) => (
                      <TableRow key={task.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          Note: {task.note}
                          <br />
                          EZWS Resp: {task.closedText}
                        </TableCell>
                        <TableCell align="right">
                          {task.task}
                          <br />
                          {task.status}
                        </TableCell>
                        <TableCell align="right">
                          {task.createdBy}
                          <Typography variant="subtitle2" sx={{ fontSize: '8px', color: 'gray' }}>
                            created: {task.created}
                          </Typography>
                          {task.closedBy}
                          <Typography variant="subtitle2" sx={{ fontSize: '8px', color: 'gray' }}>
                            {task.closedAt ? `closed: ${task.closedAt}` : null}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div>
                <br />
                No Tasks created!
                <br />
                <br />
              </div>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

OrderActionsAccordian.propTypes = {
  order: PropTypes.object,
  authtoken: PropTypes.string,
  tasks: PropTypes.array,
};
