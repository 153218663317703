// export const API_DOMAIN = `https://ezflo-test-dot-studious-linker-335113.uc.r.appspot.com/api`;
export const API_DOMAIN = `https://ezflo.ezwsllc.com/api`;

export const LOGIN_URL = `${API_DOMAIN}/login`;
export const FORGOT_PWD_URL = `${API_DOMAIN}/account/forgot_password`;
export const RESET_PWD_URL = `${API_DOMAIN}/account/reset_password`;
export const VALIDATE_TOKEN = `${API_DOMAIN}/account/token`;
export const LOGOUT_URL = `${API_DOMAIN}/logout`;

export const API_ORDERS_URL = `${API_DOMAIN}/orders`;
export const API_ORDER_URL = `${API_DOMAIN}/order`;

export const API_PPSALL_URL = `${API_DOMAIN}/ppss`;
export const API_PPS_URL = `${API_DOMAIN}/pps`;

export const API_USERS_URL = `${API_DOMAIN}/users`;
export const API_INVENTORY_UPDATE_URL = `${API_DOMAIN}/shops/inventory/set`;

export const API_STOREALL_URL = `${API_DOMAIN}/storefronts`;
export const API_STOREFRONT_URL = `${API_DOMAIN}/storefront`;

export const API_SUPPLIERSALL_URL = `${API_DOMAIN}/suppliers`;
export const API_SUPPLIER_URL = `${API_DOMAIN}/supplier`;

export const DASHBOARD_SUMMARY = `${API_DOMAIN}/dashboard/summary`;

export const API_TASKS_URL = `${API_DOMAIN}/actions`;
export const API_MASTERDATALOGS_URL = `${API_DOMAIN}/pps_supplier_sku/listing`;

export const API_MASTERDATA_FILE_UPLOAD = `${API_DOMAIN}/masterdata/upload`;
export const API_MASTERDATA_FILE_UPLOAD_LISTING = `${API_DOMAIN}/masterdata/upload/listing`;
export const API_MASTERDATA_FILE_UPLOAD_PROCESS = `${API_DOMAIN}/masterdata/upload/listing/cron`;
export const API_MASTERDATA_STORE_PUSH = `${API_DOMAIN}/suppliers/shop/product/update`;

export const API_PPSINVENTORY_FILE_UPLOAD = `${API_DOMAIN}/pps/inventory/upload`;

export const API_PPS_ORDER = `${API_DOMAIN}/test/emailconfirm`;
