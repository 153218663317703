import { useState } from 'react';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
// material

import { Button, Container, Stack, OutlinedInput, InputAdornment } from '@mui/material';

import { styled } from '@mui/material/styles';

import Iconify from '../../../components/Iconify';

// components
import PPSList from './PPSList';
import PPSProductEditModal from './PPSProductEditModal';

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_store) =>
        _store.vtitle.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _store.sku.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PPSMetadata({ authtoken, pps, refresh }) {
  const [editProduct, setEditProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState('');

  const handleAddNew = () => {
    setEditProduct({ status: 'draft', id: '' });
    setOpen(true);
    console.log('adddd');
  };

  const handleEditProduct = (product) => {
    setEditProduct(product);
    setOpen(true);
  };

  const removeEditProduct = () => {
    setEditProduct(null);
    setOpen(false);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const prods = Supplier?.masterdata ? Supplier.masterdata.items : PRODUCTS;
  const prods = pps?.items ? applySortFilter(pps.items, getComparator('asc', 'title'), filterName) : [];

  console.log(prods);
  console.log(pps);
  return (
    <Container>
      <PPSProductEditModal
        authtoken={authtoken}
        supplier={pps}
        removeEditProduct={removeEditProduct}
        product={editProduct}
        open={open}
        refresh={refresh}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <SearchStyle
          onChange={handleFilterByName}
          placeholder="Search Product..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
        &nbsp;&nbsp;
        <Button variant="contained" onClick={handleAddNew} startIcon={<Iconify icon="eva:plus-fill" />}>
          Add
        </Button>
      </Stack>
      <PPSList supplier={pps} products={prods} handleEditProduct={handleEditProduct} />
    </Container>
  );
}

PPSMetadata.propTypes = {
  authtoken: PropTypes.string,
  pps: PropTypes.object,
  refresh: PropTypes.func,
};
