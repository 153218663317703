import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import { useForm } from 'react-hook-form';
import { API_SUPPLIER_URL } from '../../../endpoints';
import getHeaders from '../../../utils/getHeaders';
import Label from '../../../components/Label';

const style = {
  position: 'absolute',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 640,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};

const styleInputsSmall = {
  width: '120px',
  marginRight: '10px',
  marginBottom: '10px',
};

async function updateSupplierProduct(authtoken, id, payload) {
  const data = fetch(`${API_SUPPLIER_URL}/${id}/masterdata/update`, {
    method: 'POST',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

export default function PPSProductEditModal({ refresh, authtoken, supplier, product, removeEditProduct, open }) {
  const { register, watch, reset } = useForm();

  const handleClose = () => {
    reset();
    removeEditProduct();
    setMessage();
  };

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const watchTitle = watch('title', '');
  const watchSku = watch('sku', '');
  const watchVariantPrice = watch('variantPrice', '');

  const disabled = watchSku === undefined || watchSku === '' || watchTitle === '' || loading;

  const onSubmit = async () => {
    setLoading(true);
    setMessage('');

    const payload = {
      id: product.id,
      title: watchTitle,
      sku: watchSku,
      variant_price: watchVariantPrice,
    };

    console.log(payload);

    const data = await updateSupplierProduct(authtoken, supplier.name, payload);
    console.log(data);
    if ('error' in data) {
      setIsError(true);
      setMessage(data?.error);
      setLoading(false);
    } else {
      setMessage(data?.message);
      setIsError(false);
      setLoading(false);
      refresh();
    }

    setLoading(false);
  };

  console.log(product);
  return (
    <div>
      {product ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {product.id === '' ? (
              <h3>Add New Product</h3>
            ) : (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <h3>Update - {product.vtitle}</h3>
                <b>SKU: {product.sku}</b>
              </Typography>
            )}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                {...register('variantPrice')}
                sx={styleInputsSmall}
                label="Price"
                defaultValue={product?.vprice}
              />
              <TextField
                {...register('inventory')}
                sx={styleInputsSmall}
                label="Inventory"
                defaultValue={product?.inventory}
              />
              <br />
              <Button variant="outlined" onClick={onSubmit} disabled={disabled}>
                Save Product
              </Button>
              &nbsp;
              <Button variant="outlined" color="secondary" onClick={handleClose}>
                Close
              </Button>
              <br />
              <br />
              {message !== '' ? (
                <Label variant="vanilla" color={isError ? 'error' : 'success'}>
                  {message}
                </Label>
              ) : null}
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}

PPSProductEditModal.propTypes = {
  removeEditProduct: PropTypes.func,
  product: PropTypes.object,
  open: PropTypes.bool,
  supplier: PropTypes.object,
  refresh: PropTypes.func,
  authtoken: PropTypes.string,
};
