import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

import { MISSING_IMAGE_URL } from '../../../constants';

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

SupplierProductCard.propTypes = {
  product: PropTypes.object,
  handleEditProduct: PropTypes.func,
};

export default function SupplierProductCard({ product, handleEditProduct }) {
  const {
    title: name,
    cover,
    price,
    status,
    priceSale,
    metadata,
    sku,
    prod_type: prodType,
    inventory,
    inventory_updated: inventoryUpdated,
  } = product;
  const imgSrc =
    metadata && 'image_src' in metadata && metadata.image_src !== '' ? metadata.image_src : MISSING_IMAGE_URL;
  const vPrice =
    metadata && 'variant_price' in metadata && metadata.variant_price !== '' ? metadata.variant_price : '-';

  const handleClick = () => {
    handleEditProduct(product);
  };

  return (
    <Card onClick={handleClick}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={((status === 'draft' || status === 'disabled') && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}
        <ProductImgStyle alt={name} src={imgSrc} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {sku}: {name}
          </Typography>
          <Typography variant="subtitle2" noWrap sx={{ color: 'grey.500' }}>
            {prodType}
          </Typography>
        </Link>

        <Typography variant="subtitle2">{vPrice !== '' ? fCurrency(vPrice) : '-'}</Typography>

        {inventory && inventoryUpdated!==''? (
          <Typography variant="subtitle2" noWrap sx={{ color: 'grey.500', fontSize: '12px' }}>
            Qty: {Math.round(inventory)} ( &#8634; : {inventoryUpdated} )
          </Typography>
        ) : <Typography variant="subtitle2" noWrap sx={{ color: 'grey.500', fontSize: '12px' }}>
            -
          </Typography>}
      </Stack>
    </Card>
  );
}
