import PropTypes from 'prop-types';
// material
import {
  Grid,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import SupplierProduct from './SupplierProduct';

// ----------------------------------------------------------------------

SupplierList.propTypes = {
  products: PropTypes.array.isRequired,
  handleEditProduct: PropTypes.func,
  Supplier: PropTypes.object,
};

export default function SupplierList({ Supplier, products, handleEditProduct, ...other }) {
  return (
    <Table>
      <TableBody>
        {products.map((product) => (
          <SupplierProduct Supplier={Supplier} product={product} handleEditProduct={handleEditProduct} />
        ))}
      </TableBody>
    </Table>
  );
}
