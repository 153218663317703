import { useState } from 'react';
import { USER_TOKEN } from './constants';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem(USER_TOKEN);

    if (tokenString) {
      return JSON.parse(tokenString);
    }

    return JSON.parse(localStorage.getItem(USER_TOKEN));
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    sessionStorage.setItem(USER_TOKEN, JSON.stringify(userToken));
    localStorage.setItem(USER_TOKEN, JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
