// @mui
import PropTypes from 'prop-types';
import { Card, Typography, CardHeader, CardContent } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
// utils
import { fDateTime } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

AppOrderTimeline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppOrderTimeline({ title, subheader, list, orders, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Timeline>
          {orders.map((item, index) => (
            <OrderSummary key={index} item={item} isLast={index === orders.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderSummary.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    orderId: PropTypes.string,
    created: PropTypes.string,
    status: PropTypes.string,
  }),
};

function OrderSummary({ item, isLast }) {
  const { orderId, created, status } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (status === 'assigned' && 'primary') ||
            (status === 'fulfilled' && 'success') ||
            (status === 'onhold' && 'error') ||
            (status === 'closed' && 'warning') ||
            'info'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{orderId}</Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {created} - ({status})
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
