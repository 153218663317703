import PropTypes from 'prop-types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { Card, CardHeader, Button } from '@mui/material';

import OrdersForm from '../../common/OrdersForm';
import { API_DOMAIN } from '../../../endpoints';

// ----------------------------------------------------------------------

OrderItems.propTypes = {
  user: PropTypes.object,
};

export default function OrderItems({ user }) {
  const downloadReport = (url) => {
    const _url = `${API_DOMAIN}/reports/lineitem_detailed?${url}&csv=1&token=${user.token}`;
    console.log(_url);
    window.open(_url);
  };

  return (
    <Card>
      <CardHeader title="Orders Details Report" subheader="(csv download by search criteria)" />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <OrdersForm user={user} callback={downloadReport} />
        <br />
        <Link href="/dashboard/reports/order-summary" underline="none">
          Order Summary Report
        </Link>
        <br />
        <Link
          href={`${API_DOMAIN}/reports/ocvar/newsletter?csv=1&token=${user.token}`}
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          Download Newsletter Signups
        </Link>
        <br />
        <Link
          href={`https://storage.googleapis.com/ezflo/moet/moet-newsletter_signups_till_2023-05-07-17-35-20.csv`}
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          Download Survey Responses
        </Link>
      </Box>
      <br />
    </Card>
  );
}
