import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { PPSsListHead, PPSsListToolbar } from '../sections/@dashboard/ppss';
// mock
import USERLIST from '../_mock/user';
import { USER_ROLE_ADMIN, USER_ROLE_PPSADMIN } from '../constants';

import { API_PPSALL_URL } from '../endpoints';

import getHeaders from '../utils/getHeaders';

async function getPPSs(authtoken) {
  const data = fetch(API_PPSALL_URL, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'alias', label: 'Alias', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_store) => _store.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PPSs({ user }) {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [Stores, setStores] = useState();
  const authtoken = user.authtoken ? user.authtoken : null;

  if (user.role === USER_ROLE_PPSADMIN) {
    navigate(`/dashboard/pps/${user.pps}`, { replace: true });
  } else if (user.role !== USER_ROLE_ADMIN) {
    navigate(`/dashboard`, { replace: true });
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPPSs(authtoken);
      setStores(data.shops);
    };
    fetchData();
  }, [authtoken]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredStores = Stores ? applySortFilter(Stores, getComparator(order, orderBy), filterName) : [];
  const isStoresNotFound = false;

  if (user.role !== USER_ROLE_ADMIN) {
    return null;
  }

  return (
    <Page title="PPS List">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Storefronts
          </Typography>
        </Stack>

        <Card>
          <PPSsListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          {filteredStores ? (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <PPSsListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredStores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, alias } = row;
                      const status = 'active';
                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Link component={RouterLink} to={`/dashboard/pps/${name}`}>
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Link>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{alias}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={(status === 'banned' && 'error') || 'success'}>
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isStoresNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          ) : null}

          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={Stores ? Stores.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

PPSs.propTypes = {
  user: PropTypes.object,
};
