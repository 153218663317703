// component
import Iconify from '../../components/Iconify';
import { USER_ROLE_ADMIN, USER_ROLE_PPSADMIN, USER_ROLE_SUPPLIER } from '../../constants';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

function navConfig(user) {
  if (user.role === USER_ROLE_SUPPLIER) return navConfigStore;
  if (user.role === USER_ROLE_PPSADMIN) return navConfigPPS;
  if (user.role === USER_ROLE_ADMIN) return navConfigAdmin;

  return navConfigUSER;
}

const navConfigUSER = [
  {
    title: 'home',
    path: '/dashboard/home',
    icon: getIcon('eva:home-outline'),
  },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: getIcon('eva:book-open-outline'),
  },
];

const navConfigAdmin = [
  {
    title: 'home',
    path: '/dashboard/home',
    icon: getIcon('eva:home-outline'),
  },
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: getIcon('eva:briefcase-outline'),
  },
  {
    title: 'PPS',
    path: '/dashboard/ppss',
    icon: getIcon('eva:car-outline'),
  },
  {
    title: 'Storefronts',
    path: '/dashboard/stores',
    icon: getIcon('eva:shopping-bag-outline'),
  },
  {
    title: 'Suppliers',
    path: '/dashboard/suppliers',
    icon: getIcon('eva:cube-outline'),
  },
  {
    title: 'Tasks',
    path: '/dashboard/tasks',
    icon: getIcon('eva:flag-outline'),
  },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: getIcon('eva:book-open-outline'),
  },
  {
    title: 'masterdata',
    path: '/dashboard/masterdata',
    icon: getIcon('eva:grid-outline'),
  },
  {
    title: 'Users',
    path: '/dashboard/users',
    icon: getIcon('eva:person-outline'),
  },
];

const navConfigPPS = [
  {
    title: 'home',
    path: '/dashboard/home',
    icon: getIcon('eva:home-outline'),
  },
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: getIcon('eva:briefcase-outline'),
  },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: getIcon('eva:book-open-outline'),
  },
];

const navConfigStore = [
  {
    title: 'home',
    path: '/dashboard/home',
    icon: getIcon('eva:home-outline'),
  },
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: getIcon('eva:briefcase-outline'),
  },
  {
    title: 'Storefronts',
    path: '/dashboard/stores',
    icon: getIcon('eva:shopping-bag-outline'),
  },
  {
    title: 'Supplier',
    path: '/dashboard/suppliers',
    icon: getIcon('eva:cube-outline'),
  },
  {
    title: 'Tasks',
    path: '/dashboard/tasks',
    icon: getIcon('eva:flag-outline'),
  },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: getIcon('eva:book-open-outline'),
  },
];

/*

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'login',
    path: '/login',
    icon: getIcon('eva:lock-fill'),
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon('eva:person-add-fill'),
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon('eva:alert-triangle-fill'),
  },
];


*/

export default navConfig;
