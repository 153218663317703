// routes
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import Router from './routes';
import Login from './pages/Login';
import useToken from './useToken';

// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

import { VALIDATE_TOKEN, LOGOUT_URL } from './endpoints';

import getHeaders from './utils/getHeaders';
import { USER_TOKEN } from './constants';

async function validateToken(authtoken) {
  const data = fetch(VALIDATE_TOKEN, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

// ----------------------------------------------------------------------

const handleLogOut = () => {
  sessionStorage.setItem(USER_TOKEN, '');
  localStorage.removeItem(USER_TOKEN);
  sessionStorage.clear();
  window.location.href = '/';
};

export default function App() {
  const { token, setToken } = useToken();
  const routePath = useLocation().pathname;

  useEffect(() => {
    const fetchData = async () => {
      const authtoken = token?.token;
      if (authtoken) {
        const data = await validateToken(authtoken);
        if ('message' in data && data.message === 'ok') {
          console.log('success');
        } else {
          console.log('logout');
          handleLogOut();
        }
      }
    };
    fetchData();
  }, [token]);

  if (
    !token &&
    !routePath.includes('reset-password') &&
    !routePath.includes('register') &&
    !routePath.includes('forgot-password') &&
    !routePath.includes('pps-oc')
  ) {
    return (
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Login setUser={setToken} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router user={token} handleLogOut={handleLogOut} />
    </ThemeProvider>
  );
}
