import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import PPSProductCard from './PPSProductCard';

// ----------------------------------------------------------------------

PPSList.propTypes = {
  products: PropTypes.array.isRequired,
  handleEditProduct: PropTypes.func,
  Supplier: PropTypes.object,
};

export default function PPSList({ Supplier, products, handleEditProduct, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <PPSProductCard Supplier={Supplier} product={product} handleEditProduct={handleEditProduct} />
        </Grid>
      ))}
    </Grid>
  );
}
