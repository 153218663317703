import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { useForm } from 'react-hook-form';
import { API_SUPPLIER_URL } from '../../../endpoints';
import getHeaders from '../../../utils/getHeaders';
import Label from '../../../components/Label';

const style = {
  position: 'absolute',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 640,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};

const styleInputsSmall = {
  width: '120px',
  marginRight: '10px',
  marginBottom: '10px',
};

async function updateSupplierProduct(authtoken, id, payload) {
  const data = fetch(`${API_SUPPLIER_URL}/${id}/masterdata/update`, {
    method: 'POST',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

export default function SupplierProductEditModal({ refresh, authtoken, supplier, product, removeEditProduct, open }) {
  const { register, watch, reset } = useForm();

  const handleClose = () => {
    reset();
    removeEditProduct();
    setMessage();
  };

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(product?.status);
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const watchBarcode = watch('barcode', '');
  const watchTitle = watch('title', '');
  const watchSku = watch('sku', '');
  const watchImageURL = watch('imageURL', '');
  const watchCustomType = watch('customType', '');
  const watchIgnoreInOrder = watch('ignoreInOrder', '');
  const watchaddSKUPrefix = watch('addSKUPrefix', '');
  const watchVariantPrice = watch('variantPrice', '');

  const disabled = watchSku === undefined || watchSku === '' || watchTitle === '' || loading;

  const onSubmit = async () => {
    setLoading(true);
    setMessage('');

    const payload = {
      id: product.id,
      title: watchTitle,
      sku: watchSku,
      status,
      image_src: watchImageURL,
      variant_price: watchVariantPrice,
      prod_type: watchCustomType,
      barcode: watchBarcode,
      ignore_in_order: watchIgnoreInOrder ? 'yes' : 'no',
      use_sku_prefix: watchaddSKUPrefix ? 'yes' : 'no',
    };

    console.log(payload);

    const data = await updateSupplierProduct(authtoken, supplier.name, payload);

    if ('error' in data) {
      setIsError(true);
      setMessage(data?.error);
      setLoading(false);
    } else {
      setMessage(data?.message);
      setIsError(false);
      setLoading(false);
      refresh();
    }

    setLoading(false);
  };

  console.log(product);
  return (
    <div>
      {product ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {product.id === '' ? (
              <h3>Add New Product</h3>
            ) : (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <h3>Update - {product.title}</h3>
                <b>SKU: {product.sku}</b>
              </Typography>
            )}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch {...register('ignoreInOrder')} defaultChecked={product.ignore_in_order === 1} />}
                  label="PPS Push Disallowed"
                />
              </FormGroup>
              <br />
              <FormGroup>
                <TextField
                  {...register('customType')}
                  id="outlined-name"
                  label="Custom Type"
                  defaultValue={product.prod_type}
                />
              </FormGroup>
              <br />
              <FormControl sx={styleInputsSmall}>
                <InputLabel>Status</InputLabel>
                <Select defaultValue={product.status} label="Status" onChange={handleStatusChange}>
                  <MenuItem value="draft">Draft</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                </Select>
              </FormControl>
              <TextField sx={styleInputsSmall} {...register('sku')} label="SKU" defaultValue={product?.sku} />
              <TextField
                {...register('variantPrice')}
                sx={styleInputsSmall}
                label="Price"
                defaultValue={product?.metadata?.variant_price}
              />
              <TextField
                sx={styleInputsSmall}
                {...register('barcode')}
                label="Barcode"
                defaultValue={product?.barcode}
              />
              <FormGroup>
                <br />
                <TextField {...register('title')} label="Title" defaultValue={product?.title} />
                <br />
                <TextField {...register('imageURL')} label="Image URL" defaultValue={product?.metadata?.image_src} />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Switch {...register('addSKUPrefix')} defaultChecked={product.use_sku_prefix === 1} />}
                  label="Add SKU Prefix"
                />
              </FormGroup>
              <br />
              <Button variant="outlined" onClick={onSubmit} disabled={disabled}>
                Save Product
              </Button>
              &nbsp;
              <Button variant="outlined" color="secondary" onClick={handleClose}>
                Close
              </Button>
              <br />
              <br />
              {message !== '' ? (
                <Label variant="vanilla" color={isError ? 'error' : 'success'}>
                  {message}
                </Label>
              ) : null}
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}

SupplierProductEditModal.propTypes = {
  removeEditProduct: PropTypes.func,
  product: PropTypes.object,
  open: PropTypes.bool,
  supplier: PropTypes.object,
  refresh: PropTypes.func,
};
