import PropTypes from 'prop-types';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function IrisDialog({ text, title, okText, cancelText, isOpen, setIsDialogOpen, setIsActionConfirmed }) {
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleOk = () => {
    setIsActionConfirmed(true);
    setIsDialogOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>{okText}</Button>
        <Button onClick={handleClose} autoFocus>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

IrisDialog.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  setIsActionConfirmed: PropTypes.func,
  setIsDialogOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};
