import { Link as RouterLink, useParams } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { ResetPasswordForm } from '../sections/auth/resetPassword';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPasswrd() {
  const mdUp = useResponsive('up', 'md');
  const { id } = useParams();

  return (
    <Page title="Reset Password">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <img src="/static/illustrations/illustration-alcohol-bottles.jpg" alt="Reset Password" />
            <br />
            <Link
              href="http://www.freepik.com"
              underline="none"
              target="_blank"
              sx={{ color: 'text.secondary', fontSize: 9, paddingLeft: 4 }}
            >
              <sup>*</sup>Illustration Designed by vectorpocket / Freepik
            </Link>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Reset Password
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Reset new password.</Typography>

            <ResetPasswordForm id={id} />

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              <Link variant="subtitle2" component={RouterLink} to="/login">
                Back to Login
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
