import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useForm } from 'react-hook-form';
import Alert from '@mui/material/Alert';

import IrisDialog from '../../common/IrisDialog';

import getHeaders from '../../../utils/getHeaders';
import Label from '../../../components/Label';

import { API_DOMAIN } from '../../../endpoints';

function setFraudFlags(authtoken, id, payload) {
  const data = fetch(`${API_DOMAIN}/orders/${id}/fraud`, {
    method: 'POST',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

function updateOrderStatus(authtoken, id, payload) {
  const data = fetch(`${API_DOMAIN}/orders/${id}/status-update`, {
    method: 'POST',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

function forceAutoAssignCall(authtoken, id) {
  const data = fetch(`${API_DOMAIN}/stores/orders/auto-assign?id=${id}&force=yes`, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

export default function OrderStatusAccordian({ authtoken, userRole, order, setEditOrder }) {
  const [newStatus, setNewStatus] = useState('');
  const [dialogText, setDialogText] = useState('Do you want to Proceed with this action?');
  const [okText, setOkText] = useState('Yes');
  const [cancelText, setCancelText] = useState('No');
  const [dialogTitle, setDialogTitle] = useState('Are you Sure?');
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [oOrder, setOOrder] = useState(order);

  const setOrderClosed = () => {
    setDialogText('Do you want to close this order?');
    setIsDialogOpen(true);
    setNewStatus('closed');
    setIsActionConfirmed(false);
  };

  const setOrderAsOnhold = () => {
    setDialogText('Do you want to mark this order as onhold?');
    setIsDialogOpen(true);
    setNewStatus('onhold');
    setIsActionConfirmed(false);
  };

  const setOrderAsFraud = () => {
    setDialogText('Do you want to mark this order as Fraud?');
    setIsDialogOpen(true);
    setNewStatus('fraud');
    setIsActionConfirmed(false);
  };

  const setOrderAsNOTFraud = () => {
    setDialogText('Do you want to mark this order as NOT Fraud?');
    setIsDialogOpen(true);
    setNewStatus('notfraud');
    setIsActionConfirmed(false);
  };

  const setOrderAsUnassigned = () => {
    setDialogText('Do you want to unassign this order?');
    setIsDialogOpen(true);
    setNewStatus('unassign');
    setIsActionConfirmed(false);
  };

  const forceAutoAssign = async () => {
    const data = await forceAutoAssignCall(authtoken, order.order_id);
    console.log(data);
    setMessage(
      <>
        <Alert severity="info">Auto Assign Force Invoked!</Alert>
        <br />
      </>
    );
  };

  const setActionConfirmed = async () => {
    setIsActionConfirmed(true);

    if (newStatus !== '') {
      if (newStatus === 'fraud' || newStatus === 'notfraud') {
        const data = await setFraudFlags(authtoken, order.order_id, { isFraud: newStatus === 'fraud' ? 1 : 0 });
        setMessage(
          <>
            <Alert severity="success">{data.message}</Alert>
            <br />
          </>
        );
        setOOrder(data.data);
        setNewStatus('');
      } else if (newStatus === 'unassign' || newStatus === 'onhold' || newStatus === 'closed') {
        const data = await updateOrderStatus(authtoken, order.order_id, { status: newStatus });
        setMessage(
          <>
            <Alert severity="success">{data.message}</Alert>
            <br />
          </>
        );
        setOOrder(data.data);
        setNewStatus('');
      }
    }
  };

  let fraudMarkup = (
    <Button variant="outlined" color="error" onClick={setOrderAsFraud}>
      Mark As Fraud
    </Button>
  );

  if (oOrder.isFraud) {
    fraudMarkup = (
      <Button variant="outlined" color="error" onClick={setOrderAsNOTFraud}>
        Mark as NOT Fraud
      </Button>
    );
  }

  let allowedStatus = null;

  if (oOrder.status === 'assigned') {
    allowedStatus = (
      <>
        <Button variant="outlined" onClick={setOrderAsUnassigned}>
          Unassign
        </Button>
        <br />
        <br />
      </>
    );
  } else if (oOrder.status === 'unassigned') {
    if (userRole === 'admin') {
      allowedStatus = (
        <>
          <Button variant="outlined" onClick={setOrderClosed}>
            Close
          </Button>{' '}
          &nbsp;&nbsp;
          <Button variant="outlined" href="#outlined-buttons" onClick={setOrderAsOnhold}>
            OnHold
          </Button>
          <br />
          <br />
        </>
      );
    } else {
      allowedStatus = (
        <>
          <Button variant="outlined" href="#outlined-buttons" onClick={setOrderAsOnhold}>
            OnHold
          </Button>
          <br />
          <br />
        </>
      );
    }
  } else if (oOrder.status === 'onhold' && userRole === 'admin') {
    allowedStatus = (
      <>
        <Button variant="outlined" onClick={setOrderClosed}>
          Close
        </Button>
        <br />
        <br />
      </>
    );
  } else if (oOrder.status === 'closed') {
    allowedStatus = (
      <>
        <br />
      </>
    );
  } else if (oOrder.status === 'suspicious') {
    if (userRole === 'admin') {
      allowedStatus = (
        <>
          <Button variant="outlined" onClick={setOrderClosed}>
            Close
          </Button>
          &nbsp;&nbsp;
          <Button variant="outlined" href="#outlined-buttons" onClick={setOrderAsOnhold}>
            OnHold
          </Button>
          <br />
          <br />
        </>
      );
    } else {
      allowedStatus = (
        <>
          <Button variant="outlined" href="#outlined-buttons" onClick={setOrderAsOnhold}>
            OnHold
          </Button>
          <br />
          <br />
        </>
      );
    }
  } else if (oOrder.status === 'assigning' && userRole === 'admin') {
    allowedStatus = (
      <>
        <Button variant="outlined" onClick={setOrderAsOnhold}>
          Onhold
        </Button>
        <br />
        <br />
      </>
    );
  }
  console.log(oOrder);

  return (
    <>
      <IrisDialog
        title={dialogTitle}
        text={dialogText}
        okText={okText}
        cancelText={cancelText}
        isOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        setIsActionConfirmed={setActionConfirmed}
      />
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">Change Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {message}
          {allowedStatus}
          {fraudMarkup}
          {oOrder.status === 'onhold' ? (
            <>
              <br />
              <br />
              <Typography>
                <Button variant="text" onClick={forceAutoAssign}>
                  Click here to force auto-assign.
                </Button>
              </Typography>
            </>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

OrderStatusAccordian.propTypes = {
  authtoken: PropTypes.string,
  order: PropTypes.object,
  userRole: PropTypes.string,
};
