import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// State management
import { RESET_PWD_URL } from '../../../endpoints';

// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import Label from '../../../components/Label';

async function resetPasswordAPI(payload) {
  return fetch(RESET_PWD_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

// ----------------------------------------------------------------------

export default function ResetPasswordForm({ id }) {
  const navigate = useNavigate();

  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const ResetPasswordSchema = Yup.object().shape({
    confirmPassword: Yup.string().required('Password is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    confirmPassword: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = methods;

  const watchConfirmPassword = watch('confirmPassword', '');
  const watchPassword = watch('password', '');

  const onSubmit = async (fdata) => {
    const data = await resetPasswordAPI({ token: id, password: watchPassword, password1: watchConfirmPassword });
    if ('error' in data) {
      setIsError(true);
      setMessage(data?.error);
    } else {
      setMessage(`Your Password has been successfully reset.`);
      setIsError(false);
      reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                  <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <br />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Reset Password
      </LoadingButton>
      {message !== '' ? (
        <>
          <br />
          <Label variant="vanilla" color={isError ? 'error' : 'success'}>
            {message}
          </Label>
          <br />
        </>
      ) : null}
    </FormProvider>
  );
}
