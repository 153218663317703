import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

import { styled } from '@mui/material/styles';

import { API_SUPPLIER_URL, API_ORDER_URL } from '../../../endpoints';
import getHeaders from '../../../utils/getHeaders';
import Label from '../../../components/Label';

import OrderStatusAccordian from './OrderStatusAccordian';
import OrderAssignAccordian from './OrderAssignAccordian';
import OrderEditAccordian from './OrderEditAccordian';
import OrderActionsAccordian from './OrderActionsAccordian';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 780,
  minHeight: 320,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};

function OrderModalHeader({ order }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="h6" sx={{ textTransform: 'uppercase', textAlign: 'left' }}>
          <Link href={order?.data?.order_status_url} target="_blank" rel="noreferrer" underline="none">
            {order.order_id}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="h6"
          sx={{ textTransform: 'uppercase', textAlign: 'center', color: order.isFraud ? 'red' : '' }}
        >
          {order.status} {order.isFraud ? <span>(Fraud)</span> : ''}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ textAlign: 'right' }} variant="body2">
          {order.created}
        </Typography>
      </Grid>
    </Grid>
  );
}

function LineItemProps({ properties }) {
  return (
    <Typography variant="caption">
      {properties.map(({ name, value }) => (
        <div>
          {name} : {value}
        </div>
      ))}
    </Typography>
  );
}

function OrderNotesProps({ noteAttributes }) {
  if (noteAttributes && noteAttributes.length > 0) {
    return (
      <Alert severity="info">
        {noteAttributes.map(({ name, value }) => (
          <div>
            {name} : {value}
          </div>
        ))}
      </Alert>
    );
  }

  return null;
}

export default function OrderEditModal({ userRole, authtoken, order, removeEditOrder, open, setEditOrder, tasks }) {
  const handleClose = () => {
    removeEditOrder();
  };

  return (
    <>
      {order ? (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <OrderModalHeader order={order} />
              {order.data.note && order.data.note !== '' ? (
                <Alert severity="info">Notes (Gifts) : {order.data.note}</Alert>
              ) : null}
              <OrderNotesProps noteAttributes={order.data.note_attributes} />
              <br />
              Total : {order.reportMeta?.summary?.total_price}, Shipping : {order.reportMeta?.summary?.shipping_total},
              Tax : {order.reportMeta?.summary?.total_tax}, Discount : {order.reportMeta?.summary?.total_discounts}
              <br />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Item sx={{ textAlign: 'left' }}>
                    <strong>Line Items</strong>
                    <ul>
                      {order.data.line_items
                        ? order.data.line_items.map((item, key) => {
                            const { properties, title, sku, price, quantity } = item;
                            console.log(properties);
                            return (
                              <li>
                                {item.title} {item.sku} (${item.price}x{item.quantity})
                                {properties && properties.length ? <LineItemProps properties={properties} /> : null}
                              </li>
                            );
                          })
                        : ''}
                    </ul>
                    <br />
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item sx={{ textAlign: 'left' }}>
                    <strong>Shipping Address</strong>
                    <br />
                    {order.data.shipping_address?.first_name ? order.data.shipping_address?.first_name : ''}
                    &nbsp;{order.data.shipping_address?.last_name ? order.data.shipping_address?.last_name : ''}
                    <br />
                    {order.data.shipping_address?.address1}
                    <br />
                    {order.data.shipping_address?.address2 ? order.data.shipping_address?.address2 : ''}
                    {order.data.shipping_address?.city ? `${order.data.shipping_address?.city}, ` : ''}
                    {order.data.shipping_address?.province_code
                      ? `${order.data.shipping_address?.province_code}, `
                      : ''}
                    {order.data.shipping_address?.zip ? order.data.shipping_address.zip : ''}
                  </Item>
                  {order.data.billing_address?.first_name !== '' ? (
                    <Item sx={{ textAlign: 'left' }}>
                      <strong>Billing Name</strong>
                      <br />
                      {order.data.billing_address?.first_name ? order.data.billing_address?.first_name : ''}
                      &nbsp;{order.data.billing_address?.last_name ? order.data.billing_address?.last_name : ''}
                      <br />
                      <Link href={order.data?.order_status_url} target="_blank" rel="noreferrer" underline="none">
                        More Details
                      </Link>
                    </Item>
                  ) : null}
                </Grid>
              </Grid>
              <br />
              {userRole === 'admin' || userRole === 'pps-admin' ? (
                <OrderStatusAccordian
                  order={order}
                  authtoken={authtoken}
                  setEditOrder={setEditOrder}
                  userRole={userRole}
                />
              ) : null}
              <OrderActionsAccordian order={order} authtoken={authtoken} tasks={tasks} userRole={userRole} />
            </Box>
          </Modal>
        </>
      ) : null}
    </>
  );
}

OrderEditModal.propTypes = {
  removeEditOrder: PropTypes.func,
  authtoken: PropTypes.string,
  open: PropTypes.bool,
  setEditOrder: PropTypes.func,
  userRole: PropTypes.string,
  order: PropTypes.object,
  tasks: PropTypes.array,
};
