import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Link as RouterLink, useParams } from 'react-router-dom';

// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  Tab,
  Tabs,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Iconify from '../components/Iconify';
import TabPanel from '../components/TabPanel';
import { SupplierMetadata } from '../sections/@dashboard/supplierMetadata';

import { API_SUPPLIER_URL } from '../endpoints';

import getHeaders from '../utils/getHeaders';
import a11yProps from '../utils/a11yProps';

async function getSupplier(authtoken, id) {
  const data = fetch(`${API_SUPPLIER_URL}/${id}`, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

async function updateSupplier(authtoken, id, payload) {
  const data = fetch(`${API_SUPPLIER_URL}/${id}`, {
    method: 'PUT',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

export default function SMasterdata({ user }) {
  const authtoken = user.token ? user.token : null;
  const { id } = useParams();
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState(0);
  const [supplier, setSupplier] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { register, watch } = useForm();
  const watchCustomTypes = watch('customTypes', '');

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSupplier(authtoken, id);
      setSupplier(data.supplier);
    };
    fetchData();
  }, [authtoken]);

  const refresh = async () => {
    const fetchData = async () => {
      const data = await getSupplier(authtoken, id);
      setSupplier(data.supplier);
    };
    fetchData();
  };

  console.log(supplier);

  const onSubmit = async () => {
    setIsLoading(true);
    setMessage('');
    const data = await updateSupplier(authtoken, id, { custom_types: watchCustomTypes });
    console.log(data);
    if ('error' in data) {
      setIsError(true);
      setMessage(data?.error);
      setIsLoading(false);
    } else {
      setMessage(`Success!`);
      setIsError(false);
      setIsLoading(false);
      refresh();
    }
  };

  return (
    <Page title="Supplier">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            {id} : Supplier
          </Typography>
        </Stack>

        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Masterdata" {...a11yProps(0)} />
              <Tab label="Settings" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <SupplierMetadata refresh={refresh} authtoken={authtoken} supplier={supplier} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant="h6">Ignore 'Custom Types'</Typography>
            <Typography variant="caption">( Each type should be seperated by a ';' )</Typography>
            <TextField
              {...register('customTypes')}
              defaultValue={supplier?.cfg.custom_types}
              placeholder="MultiLine with rows: 2 and rowsMax: 4"
              multiline
              rows={2}
              maxRows={4}
              fullWidth
            />
            <br />
            <br />
            <Button variant="outlined" onClick={onSubmit} disabled={isLoading}>
              Update
            </Button>
            <br />
            <Typography variant="caption">
              * Any line-item with type in the list above will{' '}
              <b>
                <u>not be pushed</u>
              </b>{' '}
              as a part of order to PPS.
              <br />
              {message !== '' ? (
                <Label variant="vanilla" color={isError ? 'error' : 'success'}>
                  {message}
                </Label>
              ) : null}
            </Typography>
          </TabPanel>
        </Card>
      </Container>
    </Page>
  );
}

SMasterdata.propTypes = {
  user: PropTypes.object,
};
