import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { filter } from 'lodash';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { TasksListHead, TasksListToolbar, TaskEditModal } from '../sections/@dashboard/tasks';

// components
import Page from '../components/Page';

import { USER_ROLE_ADMIN, USER_ROLE_SUPPLIER } from '../constants';

import { API_TASKS_URL } from '../endpoints';

import getHeaders from '../utils/getHeaders';

async function getTasks(authtoken) {
  const data = fetch(API_TASKS_URL, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'entityID', label: 'Entity', alignRight: false },
  { id: 'status', label: 'T. Status', alignRight: false },
  { id: 'task', label: 'Task', alignRight: false },
  { id: 'createdBy', label: '', alignRight: false },
  { id: 'closedBy', label: '', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.entityID.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function TaskLink({ item, handleEditTask }) {
  const handleClick = () => {
    handleEditTask(item);
  };

  return (
    <Card onClick={handleClick} style={{ border: 'none', boxShadow: 'none', cursor: 'pointers', color: '#2065D1' }}>
      <Typography variant="h7">{item.entityID}</Typography>
    </Card>
  );
}

export default function Tasks({ user }) {
  const navigate = useNavigate();

  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const authtoken = user.token ? user.token : null;
  console.log(user);
  if (user.role !== USER_ROLE_ADMIN && user.role !== USER_ROLE_SUPPLIER) {
    navigate(`/dashboard`, { replace: true });
  }

  const [editTask, setEditTask] = useState(null);
  const [open, setOpen] = useState(false);

  const refreshTasks = async (pg) => {
    const data = await getTasks(authtoken);
    setTasks(data.items);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const data = await getTasks(authtoken);
        setTasks(data.items);
      }
    };
    fetchData();
  }, [authtoken]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tasks.length) : 0;

  const filteredUsers = tasks ? applySortFilter(tasks, getComparator(order, orderBy), filterName) : [];
  // const filteredUsers = Users;
  const isUserNotFound = filteredUsers?.length === 0;

  const handleEditTask = (item) => {
    if (user.role === 'admin') {
      setEditTask(item);
      console.log(item);
      setOpen(true);
    }
  };

  const removeEditTask = () => {
    setEditTask(null);
    setOpen(false);
    refreshTasks(page);
  };

  return (
    <Page title="Tasks">
      <TaskEditModal
        authtoken={user.token}
        removeEditTask={removeEditTask}
        task={editTask}
        setEditTask={setEditTask}
        open={open}
        userRole={user.role}
      />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Tasks
          </Typography>
        </Stack>
        <TasksListToolbar filterName={filterName} onFilterName={handleFilterByName} />

        <Card>
          {filteredUsers ? (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TasksListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tasks.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        id,
                        entityID,
                        created,
                        closedAt,
                        status,
                        task,
                        createdBy,
                        note,
                        closedBy,
                        closedText,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <TaskLink item={row} handleEditTask={handleEditTask} />
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === 'complete' && 'success') || (status === 'closed' && 'warning') || 'info'
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{task}</TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                              created: {createdBy} <br />
                              <Typography variant="subtitle2" sx={{ fontSize: '8px', color: 'gray' }}>
                                {created}
                              </Typography>
                              {task !== 'note' && closedBy && closedBy !== '' ? <>closed: {closedBy}</> : null}
                              {task !== 'note' ? (
                                <Typography variant="subtitle2" sx={{ fontSize: '8px', color: 'gray' }}>
                                  {closedAt}
                                </Typography>
                              ) : null}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ fontSize: '12px' }}>
                            Note: {note}
                            <br />
                            EZWS Resp: {closedText}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          ) : null}

          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={tasks ? tasks.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

Tasks.propTypes = {
  user: PropTypes.object,
};
