import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button } from '@mui/material';

import { useForm } from 'react-hook-form';
import getHeaders from '../../utils/getHeaders';

import MultiSelectCheckmarks from './MultiSelectCheckmarks';

import { ORDER_FORM_FILTER_STATUS, ORDER_FORM_FILTER_STATUS_ADMIN, ORDER_FORM_EXCEPTIONS } from '../../constants';

// ----------------------------------------------------------------------

import { API_STOREALL_URL, API_PPSALL_URL } from '../../endpoints';

async function getStores(authtoken) {
  const data = fetch(API_STOREALL_URL, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

async function getPPSs(authtoken) {
  const data = fetch(API_PPSALL_URL, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

OrdersForm.propTypes = {
  user: PropTypes.object,
  updateURL: PropTypes.func,
};

const styleSearch = {
  maxWidth: '640px',
  width: '98%',
  marginRight: '10px',
  marginBottom: '10px',
};

function getAllStores(inp) {
  const as = [];
  for (let x = 0; x < inp.length; x += 1) {
    as.push(inp[x].name);
  }
  return as;
}

function getAllPPSs(inp) {
  const as = [];
  for (let x = 0; x < inp.length; x += 1) {
    as.push(inp[x].name);
  }
  return as;
}

export default function OrdersForm({ user, callback }) {
  const { register, watch } = useForm();

  const [statuses, setStatuses] = useState([]);
  const [pPSs, setPPSs] = useState([]);
  const [stores, setStores] = useState([]);
  const [exceptions, setExceptions] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const handleFromChange = (newValue) => {
    setFromDate(newValue);
  };
  const handleToChange = (newValue) => {
    setToDate(newValue);
  };

  const [allStores, setAllStores] = useState([]);
  const [allPPSs, setAllPPSs] = useState([]);

  const authtoken = user.token ? user.token : null;

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStores(authtoken);
      setAllStores(data.stores);
    };
    fetchData();
  }, [authtoken]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPPSs(authtoken);
      setAllPPSs(data.shops);
    };
    fetchData();
  }, [authtoken]);

  const watchSearchText = watch('searchText', '');

  const refreshOrderItemURL = useMemo(() => {
    let url = ``;
    const storesInp = stores.join().replace(',', ';');
    const ppsInp = pPSs.join().replace(',', ';');
    const statusInp = statuses.join().replace(',', ';');

    let expInt = 0;
    if (exceptions.length) {
      for (let i = 0; i < exceptions.length; i += 1) {
        const exp = exceptions[i].toLowerCase();
        console.log(exp);
        if (exp === 'fraud') {
          expInt |= 1; // eslint-disable-line no-bitwise
        } else if (exp === 'reship') {
          expInt |= 2; // eslint-disable-line no-bitwise
          expInt |= 64; // eslint-disable-line no-bitwise
        } else if (exp === 'refund') {
          expInt |= 4; // eslint-disable-line no-bitwise
        } else if (exp === 'replace') {
          expInt |= 8; // eslint-disable-line no-bitwise
          expInt |= 128; // eslint-disable-line no-bitwise
        } else if (exp === 'delivery exception') {
          expInt |= 16; // eslint-disable-line no-bitwise
        } else if (exp === 'return received') {
          expInt |= 32; // eslint-disable-line no-bitwise
        } else if (exp === 'cancel') {
          expInt |= 256; // eslint-disable-line no-bitwise
        } else if (exp === 'missing shipping label') {
          expInt |= 512; // eslint-disable-line no-bitwise
        }
      }
    }

    if (watchSearchText) {
      url += `q=${watchSearchText}`;
    }
    url += `&status=${statusInp}&pps=${ppsInp}&store=${storesInp}`;
    url += `&exceptions=${expInt}`;

    if (fromDate) {
      url += `&from=${fromDate.format('YYYY-MM-DD')}`;
    }
    if (toDate) {
      url += `&to=${toDate.format('YYYY-MM-DD')}`;
    }
    return `${url}`;
  }, [watchSearchText, fromDate, toDate, stores, pPSs, statuses, exceptions]);

  let allowedStores = [];
  if (user?.role === 'supplier') {
    allowedStores = user.store.split(';');
  } else {
    allowedStores = getAllStores(allStores);
  }

  let allowedPPSs = [];
  if (user?.role === 'pps-admin') {
    allowedPPSs = [user.pps];
  } else {
    allowedPPSs = getAllPPSs(allPPSs);
  }

  const onClick = async () => {
    callback(refreshOrderItemURL);
  };

  console.log(refreshOrderItemURL);

  const allowedStatus = user.role === 'admin' ? ORDER_FORM_FILTER_STATUS_ADMIN : ORDER_FORM_FILTER_STATUS;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TextField
          sx={styleSearch}
          {...register('searchText')}
          id="outlined-name"
          label="Enter Search Text"
          placeholder={'Enter Search text..'}
        />
        <MultiSelectCheckmarks
          caption={'Status'}
          widthVal={180}
          allowed={user.role === 'admin' ? ORDER_FORM_FILTER_STATUS_ADMIN : ORDER_FORM_FILTER_STATUS}
          setFormValues={setStatuses}
        />
        <MultiSelectCheckmarks caption={'Stores'} widthVal={240} allowed={allowedStores} setFormValues={setStores} />
        {user.role !== 'supplier' ? (
          <MultiSelectCheckmarks caption={'PPS'} widthVal={240} allowed={allowedPPSs} setFormValues={setPPSs} />
        ) : null}
        <MultiSelectCheckmarks
          caption={'Exceptions'}
          widthVal={240}
          allowed={ORDER_FORM_EXCEPTIONS}
          setFormValues={setExceptions}
        />
        <MobileDatePicker
          label="From"
          inputFormat="MM/DD/YYYY"
          value={fromDate}
          onChange={handleFromChange}
          renderInput={(params) => <TextField {...params} />}
        />
        &nbsp;&nbsp;
        <MobileDatePicker
          label="To"
          inputFormat="MM/DD/YYYY"
          value={toDate}
          onChange={handleToChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <br />
        <Button
          onClick={onClick}
          variant="contained"
          sx={{ padding: '14px 80px', marginTop: '20px', minWidth: '240px' }}
        >
          Submit
        </Button>
      </LocalizationProvider>
      <br />
    </>
  );
}
