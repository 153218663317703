import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';

// material
import { Stack, Container, Typography, Button, Tabs, Tab, Box } from '@mui/material';

// components
import Page from '../components/Page';
import Label from '../components/Label';

import {
  API_SUPPLIERSALL_URL,
  API_PPSALL_URL,
  API_MASTERDATA_FILE_UPLOAD,
  API_MASTERDATA_FILE_UPLOAD_LISTING,
  API_MASTERDATA_FILE_UPLOAD_PROCESS,
  API_SUPPLIER_URL,
} from '../endpoints';
import getHeaders from '../utils/getHeaders';
import { USER_ROLE_ADMIN, USER_ROLE_SUPPLIER } from '../constants';

async function getPPSs(authtoken) {
  const data = fetch(API_PPSALL_URL, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

async function getJobListing(authtoken) {
  const data = fetch(API_MASTERDATA_FILE_UPLOAD_LISTING, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

async function getSuppliers(authtoken) {
  const data = fetch(API_SUPPLIERSALL_URL, {
    method: 'GET',
    headers: {
      Authorization: `${authtoken}`,
    },
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

async function updateSupplier(authtoken, id, payload) {
  const data = fetch(`${API_SUPPLIER_URL}/${id}`, {
    method: 'PUT',
    headers: getHeaders(authtoken),
    body: JSON.stringify(payload),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

function JobDetails({ row }) {
  if (row.status === 'qued') {
    return null;
  }

  if (row.status === 'ok') {
    return (
      <Link
        href={`/dashboard/supplier-masterdata/${row?.details?.suppliersInFile.join(', ')}`}
        rel="noreferrer"
        underline="none"
      >
        <Alert severity="info">Details (SKUS Total {row?.details?.total})</Alert>
      </Link>
    );
  }

  if (row.status === 'error') {
    return (
      <Alert severity="error">
        {row?.details?.missing && row?.details?.missing.length > 0 && (
          <>
            Missing Fields: {row?.details?.missing.join(', ')}
            <br />
          </>
        )}
        {row?.supplier === 'multiple' && (
          <>
            Multiple Suppliers: {row?.details?.suppliersInFile.join(', ')}
            <br />
          </>
        )}
        {row?.details?.supplierinvalid !== '' && (
          <>
            Invalid Supplier: {row?.details?.suppliersInFile.join(', ')}
            <br />
          </>
        )}
        SKUS Total {row?.details?.total}
      </Alert>
    );
  }
  return null;
}

function BasicTable({ jobListings }) {
  console.log(jobListings);
  return (
    <TableContainer component={Paper} sx={{ marginTop: '50px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>File</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Details</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobListings.map((row) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">
                <Link href={row.fileURL} target="_blank" rel="noreferrer" underline="none">
                  Download
                </Link>
              </TableCell>
              <TableCell align="left">{row.created}</TableCell>
              <TableCell align="left">
                {row.status === 'ok' ? (
                  <Link
                    href={`/dashboard/supplier-masterdata/${row?.details?.suppliersInFile.join(', ')}`}
                    rel="noreferrer"
                    underline="none"
                  >
                    <strong>supplier :{row.supplier}</strong>
                  </Link>
                ) : (
                  <strong>supplier :{row.supplier}</strong>
                )}
                &nbsp;| uploader : <strong>{row.created_by}</strong>
                <br />
                <JobDetails row={row} />
              </TableCell>
              <TableCell align="right">{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const SupplierOption = styled(Button)(({ selected }) => ({
  backgroundColor: selected ? '#2065D1' : 'grey',
  margin: '6px 8px',
  '&:hover': {
    backgroundColor: selected ? '#2065D1' : 'darkgrey',
  },
}));

export default function Masterdata({ user }) {
  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState(null);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSelectedClick = (value) => {
    setSelected(value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const authtoken = user.token ? user.token : null;
  const [suppliers, setSuppliers] = useState();
  const [pPSs, setPPSs] = useState();
  const [jobListings, setJobListings] = useState();

  const [message2, setMessage2] = useState('');
  const [supplierPPSsText, setSupplierPPSsText] = useState('');

  const [isError, setIsError] = useState(false);

  const { register, watch } = useForm();

  const refreshListing = async () => {
    const data3 = await getJobListing(authtoken);
    setJobListings(data3.items);
  };

  const supplierSelected = useMemo(() => {
    if (selected !== '' && suppliers) {
      for (let i = 0; i < suppliers.length; i += 1) {
        if (suppliers[i].name === selected) {
          setSupplierPPSsText(suppliers[i].linkedPPS);
          return suppliers[i];
        }
      }
    }
    return null;
  }, [selected]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSuppliers(authtoken);
      setSuppliers(data.suppliers);

      const data2 = await getPPSs(authtoken);
      setPPSs(data2.shops);

      refreshListing();
    };
    fetchData();
  }, [authtoken]);

  const uploadFileSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setMessage('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(API_MASTERDATA_FILE_UPLOAD, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: authtoken,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setMessage(errorData.message);
        return;
      }
      setMessage('Success!');
      refreshListing();

      const data = fetch(API_MASTERDATA_FILE_UPLOAD_PROCESS, { method: 'GET' })
        .then((data) => data.json())
        .catch((e) => {
          console.error(e);
        });
    } catch (error) {
      setMessage('Error occurred while uploading the file');
    }
  };

  const onSubmitSupplierPPS = async () => {
    let sid = '';
    for (let i = 0; i < suppliers.length; i += 1) {
      if (suppliers[i].name === selected) {
        sid = suppliers[i].id;
      }
    }
    setMessage2('');
    // sid = 'd';

    const data = await updateSupplier(authtoken, selected, { linked_PPSs: supplierPPSsText });

    if ('error' in data) {
      setIsError(true);
      setMessage2(data?.error);
    } else {
      setMessage2(`Success!`);
      setIsError(false);
    }
  };

  console.log(supplierSelected);
  console.log(supplierPPSsText);

  const handleTextFieldChange = (event) => {
    setSupplierPPSsText(event.target.value);
  };

  return (
    <Page title="Masterdata">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Masterdata (<Link href="/dashboard/masterdatalogs" target="_blank" rel="noreferrer" underline="none">
                  logs
                </Link>)
          </Typography>
        </Stack>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Jobs" {...a11yProps(0)} />
              <Tab label="Mappings" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <form onSubmit={uploadFileSubmit}>
              <TextField type="file" accept=".csv" variant="outlined" onChange={handleFileChange} />
              <br />
              <Box sx={{ marginTop: '20px' }}>
                <Button type="submit" variant="contained">
                  Upload
                </Button>
                <span sx={{ fontSize: '10px' }}> ( Download Sample File )</span>
              </Box>
              <br />
            </form>
            <div>{message}</div>
            {jobListings && <BasicTable jobListings={jobListings} />}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {suppliers &&
              suppliers.map((row) => {
                const { id, name, url, sku_prefix: skuPrefix } = row;
                return (
                  <>
                    <SupplierOption
                      variant="contained"
                      value={name}
                      selected={selected === name}
                      onClick={() => handleSelectedClick(name)}
                    >
                      {name}
                    </SupplierOption>
                  </>
                );
              })}

            {selected ? (
              <Box sx={{ margin: '50px 10px 30px', padding: '30px 0px' }}>
                Mapped PPS for <strong>{selected.toUpperCase()}</strong>
                <br />
                <Typography variant="caption">( Each type should be seperated by a ';' )</Typography>
                <TextField
                  value={supplierPPSsText}
                  placeholder=""
                  multiline
                  rows={2}
                  maxRows={4}
                  fullWidth
                  onChange={handleTextFieldChange}
                />
                <br />
                <br />
                <Button variant="outlined" onClick={onSubmitSupplierPPS}>
                  Update
                </Button>
                <br />
                {message2 !== '' ? (
                  <Label variant="vanilla" color={isError ? 'error' : 'success'}>
                    {message2}
                  </Label>
                ) : null}
              </Box>
            ) : null}
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

Masterdata.propTypes = {
  user: PropTypes.object,
};
