import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// material
// material
import { Box, Card, Stack, Button, Tab, Tabs, Container, Typography } from '@mui/material'; // components
import TextField from '@mui/material/TextField';

import Page from '../components/Page';
import Label from '../components/Label';
import { PPSMetadata } from '../sections/@dashboard/pps';

import TabPanel from '../components/TabPanel';

import { API_PPS_URL, API_PPSINVENTORY_FILE_UPLOAD } from '../endpoints';

import getHeaders from '../utils/getHeaders';
import a11yProps from '../utils/a11yProps';

async function getPPS(authtoken, id) {
  const data = fetch(`${API_PPS_URL}/${id}`, {
    method: 'GET',
    headers: getHeaders(authtoken),
  })
    .then((data) => data.json())
    .catch((e) => {
      console.error(e);
    });
  return data;
}

export default function PPS({ user }) {
  const authtoken = user.token ? user.token : null;
  const { id } = useParams();
  const [pPS, setPPS] = useState(null);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [fileMessage, setFileMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const uploadFileSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setFileMessage('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('pps', id);

    try {
      const response = await fetch(API_PPSINVENTORY_FILE_UPLOAD, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: authtoken,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setFileMessage(errorData.message);
        return;
      }
      setFileMessage('Success!');
      refresh();
    } catch (error) {
      setFileMessage('Error occurred while uploading the file');
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPPS(authtoken, id);
      setPPS(data.details);
    };
    fetchData();
  }, [authtoken, id]);

  const refresh = async () => {
    const fetchData = async () => {
      const data = await getPPS(authtoken, id);
      setPPS(data.details);
    };
    fetchData();
  };

  return (
    <Page title="PPS">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            {id} : PPS
          </Typography>
        </Stack>

        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Items" {...a11yProps(0)} />
              <Tab label="Settings" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PPSMetadata refresh={refresh} authtoken={authtoken} pps={pPS} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant="h6">Update Inventory</Typography>
            <br />
            <form onSubmit={uploadFileSubmit}>
              <TextField type="file" accept=".csv" variant="outlined" onChange={handleFileChange} />
              <br />
              <Box sx={{ marginTop: '20px' }}>
                <Button type="submit" variant="contained">
                  Upload
                </Button>
                <span sx={{ fontSize: '10px' }}> ( Download Sample File )</span>
              </Box>
              <br />
            </form>
            <div>{fileMessage}</div>

            <br />
            <br />

            <br />
            <Typography variant="caption">
              <br />
              {message !== '' ? (
                <Label variant="vanilla" color={isError ? 'error' : 'success'}>
                  {message}
                </Label>
              ) : null}
            </Typography>
          </TabPanel>
        </Card>
      </Container>
    </Page>
  );
}

PPS.propTypes = {
  user: PropTypes.object,
};
