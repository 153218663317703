import { Navigate, useRoutes } from 'react-router-dom';
import PropTypes from 'prop-types';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import Users from './pages/Users';
import Login from './pages/Login';
import OC from './pages/OC';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import Storefronts from './pages/Storefronts';
import PPSs from './pages/PPSs';
import PPS from './pages/PPS';
import Store from './pages/Store';
import Suppliers from './pages/Suppliers';
import Supplier from './pages/Supplier';

import NotFound from './pages/Page404';
import Register from './pages/Register';
import Reports from './pages/Reports';
import Tasks from './pages/Tasks';
import Orders from './pages/Orders';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import DashboardHome from './pages/DashboardHome';
import Masterdata from './pages/Masterdata';
import SMasterdata from './pages/SMasterdata';
import MasterdataLogs from './pages/MasterdataLogs';

// ----------------------------------------------------------------------

export default function Router({ user, handleLogOut }) {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout user={user} handleLogOut={handleLogOut} />,
      children: [
        { path: '', element: <DashboardHome user={user} /> },
        { path: 'app', element: <DashboardApp user={user} /> },
        { path: 'home', element: <DashboardHome user={user} /> },
        { path: 'users', element: <Users user={user} /> },
        { path: 'stores', element: <Storefronts user={user} /> },
        { path: 'ppss', element: <PPSs user={user} /> },
        { path: 'pps/:id', element: <PPS user={user} /> },
        { path: 'supplier/:id', element: <Supplier user={user} /> },
        { path: 'suppliers', element: <Suppliers user={user} /> },
        { path: 'products', element: <Products user={user} /> },
        { path: 'blog', element: <Blog user={user} /> },
        { path: 'reports', element: <Reports user={user} /> },
        { path: 'reports/:id', element: <Reports user={user} /> },
        { path: 'tasks', element: <Tasks user={user} /> },
        { path: 'masterdata', element: <Masterdata user={user} /> },
        { path: 'supplier-masterdata/:id', element: <SMasterdata user={user} /> },
        { path: 'masterdatalogs', element: <MasterdataLogs user={user} /> },
        {
          path: 'orders',
          element: <Orders user={user} />,
          children: [{ path: ':pno/:id', element: <Orders user={user} /> }],
        },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'pps-oc/:id',
      element: <OC />,
    },
    {
      path: 'reset-password/:id',
      element: <ResetPassword />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/home" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}

Router.propTypes = {
  user: PropTypes.object,
  handleLogOut: PropTypes.func,
};
